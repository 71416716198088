import template from "./edit-vessel.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";
import featuresClient from "Clients/features-client";
import Vessel from "ViewModels/vesselsAdministration/vessel";

function ViewModel(params) {
  var self = this;

  self.companies = params.companies;
  self.numberOfShaftsOptions = [0, 1, 2];
  self.assignedVessels = params.assignedVessels;
  self.vessel = params.vessel ? params.vessel : ko.observable(new Vessel());

  self.dataReceiver = ko.observable();
  self.features = ko.observableArray([]);

  self.getFeatures();

  self.getDataReceiverEvent = events.findDataReceiver.add(function () {
    if (!self.isVesselEmpty()) {
      self.getDataReceiverByImoNumber();
    }
  });

  self.isVesselEmpty = ko.pureComputed(function () {
    var vessel = self.vessel();
    if (vessel.id()) {
      return false;
    }

    return true;
  });

  self.isImoNumberTaken = ko.pureComputed(function () {
    if (self.vessel()) {
      return self.companyContainsVesselWithImoNumber(self.vessel());
    }
    return false;
  });
}

ViewModel.prototype.companyContainsVesselWithImoNumber = function (vessel) {
  var self = this;
  var selectedCompany = vessel.company();
  var assignedVessels = self.assignedVessels();
  if (selectedCompany) {
    return _.some(assignedVessels, function (assignedVessel) {
      var isImoNumberInCompany = self.isImoNumberInCompany(assignedVessel, selectedCompany, vessel);
      return isImoNumberInCompany && assignedVessel !== vessel;
    });
  }
  return false;
};

ViewModel.prototype.isImoNumberInCompany = function (assignedVessel, selectedCompany, vessel) {
  return assignedVessel.company() &&
    assignedVessel.company().id === selectedCompany.id &&
    assignedVessel.imoNumber() === Number(vessel.imoNumber());
};

ViewModel.prototype.getFeatures = function () {
  var self = this;
  featuresClient.getAll().done(self.features);
};

ViewModel.prototype.getDataReceiverByImoNumber = function () {
  var self = this;
  var imoNumber = self.vessel().imoNumber();
  vesselsAdministrationsClient.getDataReceiverByImoNumber(imoNumber).done(function (data) {
    self.dataReceiver(data);
  });
};

ViewModel.prototype.dispose = function () {
  this.getDataReceiverEvent.detach();
};

export default { viewModel: ViewModel, template: template };
