import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";
import dateHelper from "Utilities/date-helper";
import store from "@/store/index";

function Vessel(vessel, companies) {
  var self = this;
  self.companies = companies;

  self.vessel = ko.observable(vessel);

  self.id = ko.observable();
  self.name = ko.observable();
  self.company = ko.observable();
  self.imoNumber = ko.observable();
  self.created = ko.observable();
  self.lastExportDate = ko.observable();
  self.companyName = ko.observable();
  self.draftMean = ko.observable();
  self.ballastDraftHigh = ko.observable();
  self.ballastDraftLow = ko.observable();
  self.ladenDraftHigh = ko.observable();
  self.ladenDraftLow = ko.observable();
  self.maxContinuousRating = ko.observable(0);
  self.speedMaxContinuousRating = ko.observable(0);
  self.numberOfShafts = ko.observable(0);
  self.activeStatus = ko.observable();
  self.isDataReceiver = ko.observable();
  self.draftMeanErrorMessage = ko.observable();
  self.ballastDraftHighErrorMessage = ko.observable();
  self.ballastDraftLowErrorMessage = ko.observable();
  self.ladenDraftHighErrorMessage = ko.observable();
  self.ladenDraftLowErrorMessage = ko.observable();
  self.maxContinuousRatingErrorMessage = ko.observable();
  self.speedMaxContinuousRatingErrorMessage = ko.observable();
  self.imoNumberErrorMessage = ko.observable();
  self.nameErrorMessage = ko.observable();
  self.isImoNumberValid = ko.observable(true);
  self.isDraftValid = ko.observable(true);
  self.isBallastDraftHighValid = ko.observable(true);
  self.isBallastDraftLowValid = ko.observable(true);
  self.isLadenDraftHighValid = ko.observable(true);
  self.isLadenDraftLowValid = ko.observable(true);
  self.isMaxContinuousRatingValid = ko.observable(true);
  self.isSpeedMaxContinuousRatingValid = ko.observable(true);
  self.isNameValid = ko.observable(true);
  self.enabledFeatures = [];

  if (vessel) {
    self.id(vessel.id);
    self.name(vessel.name);
    self.imoNumber(vessel.imoNumber);
    self.created(vessel.created);
    self.lastExportDate(_.isNull(vessel.lastExportDate) ? "" : vessel.lastExportDate);
    self.companyName(vessel.companyName);
    self.draftMean(vessel.draftMean);
    self.ballastDraftHigh(vessel.ballastDraftHigh);
    self.ballastDraftLow(vessel.ballastDraftLow);
    self.ladenDraftHigh(vessel.ladenDraftHigh);
    self.ladenDraftLow(vessel.ladenDraftLow);
    self.maxContinuousRating(vessel.maxContinuousRating);
    self.speedMaxContinuousRating(vessel.speedMaxContinuousRating);
    self.numberOfShafts(vessel.numberOfShafts);
    self.activeStatus(vessel.activeStatus);
    self.isDataReceiver(vessel.isDataReceiver);
    self.company(self.getCompany(self.companyName()));
    self.enabledFeatures = vessel.features;
  }

  self.isActive = ko.pureComputed(function () {
    return self.activeStatus() === "Active";
  });

  self.formattedImoNumber = ko.pureComputed(function () {
    var imoNumber = self.imoNumber();
    var formattedNumber = ("0000000" + imoNumber).slice(-7);
    return "IMO" + formattedNumber;
  });

  self.formattedCreatedDate = ko.pureComputed(function () {
    return dateHelper.getFormatedDateString(self.created());
  });

  self.formattedLastExportDate = ko.pureComputed(function () {
    return dateHelper.getFormatedDateString(self.lastExportDate());
  });

  self.isVesselValid = ko.pureComputed(function () {
    return self.isNameValid() && self.isImoNumberValid() && self.isDraftValid()
      && self.isBallastDraftHighValid() && self.isBallastDraftLowValid()
      && self.isLadenDraftHighValid() && self.isLadenDraftLowValid() && self.isMaxContinuousRatingValid() && self.isSpeedMaxContinuousRatingValid();
  });

  self.nameBinding = self.name.subscribe(function (imoNumber) {
    var errorMessage = self.getNameErrorMessage();
    self.validateField(errorMessage, self.nameErrorMessage, self.isNameValid);
  });

  self.imoNumberBinding = self.imoNumber.subscribe(function (imoNumber) {
    var errorMessage = self.getImoNumberErrorMessage();
    self.validateField(errorMessage, self.imoNumberErrorMessage, self.isImoNumberValid);
  });

  self.draftMeanBinding = self.draftMean.subscribe(function (draftMean) {
    var errorMessage = self.getDraftMeanErrorMessage();
    self.validateField(errorMessage, self.draftMeanErrorMessage, self.isDraftValid);
  });

  self.ballastDraftHighBinding = self.ballastDraftHigh.subscribe(function (ballastDraftHigh) {
    const errorMessage = self.getBallastDraftHighErrorMessage();
    self.validateField(errorMessage, self.ballastDraftHighErrorMessage, self.isBallastDraftHighValid);
  });

  self.ballastDraftLowBinding = self.ballastDraftLow.subscribe(function (ballastDraftLow) {
    const errorMessage = self.getBallastDraftLowErrorMessage();
    self.validateField(errorMessage, self.ballastDraftLowErrorMessage, self.isBallastDraftLowValid);
  });

  self.ladenDraftHighBinding = self.ladenDraftHigh.subscribe(function (ladenDraftHigh) {
    const errorMessage = self.getLadenDraftHighErrorMessage();
    self.validateField(errorMessage, self.ladenDraftHighErrorMessage, self.isLadenDraftHighValid);
  });

  self.ladenDraftLowBinding = self.ladenDraftLow.subscribe(function (ladenDraftLow) {
    const errorMessage = self.getLadenDraftLowErrorMessage();
    self.validateField(errorMessage, self.ladenDraftLowErrorMessage, self.isLadenDraftLowValid);
  });

  self.maxContinuousRatingBinding = self.maxContinuousRating.subscribe(function (maxContinuousRating) {
    const errorMessage = self.getMaxContinuousRatingErrorMessage();
    self.validateField(errorMessage, self.maxContinuousRatingErrorMessage, self.isMaxContinuousRatingValid);
  });

  self.speedMaxContinuousRatingBinding = self.speedMaxContinuousRating.subscribe(function (speedMaxContinuousRating) {
    const errorMessage = self.getSpeedMaxContinuousRatingErrorMessage();
    self.validateField(errorMessage, self.speedMaxContinuousRatingErrorMessage, self.isSpeedMaxContinuousRatingValid);
  });
};

Vessel.prototype.validateField = function (errorMessage, errorMessageObservable, fieldValidationObservable) {
  if (errorMessage) {
    errorMessageObservable(errorMessage);
    fieldValidationObservable(false);
  } else {
    errorMessageObservable("");
    fieldValidationObservable(true);
  }
};

Vessel.prototype.getNameErrorMessage = function () {
  var errorMessage;
  var name = this.name();

  if (_.isUndefined(name) || name === "") {
    errorMessage = "Vessel name is required";
  } else if (name.length > 30) {
    errorMessage = "Vessel name may not be longer than 30 characters";
  }
  return errorMessage;
};

Vessel.prototype.getImoNumberErrorMessage = function () {
  var errorMessage;
  var imoNumber = this.imoNumber();

  if (_.isUndefined(imoNumber) || imoNumber === "") {
    errorMessage = "IMO number is required";
  } else {
    imoNumber = parseFloat(imoNumber);
    if (!_.isFinite(imoNumber)) {
      errorMessage = "IMO number must be a number";
    } else if (imoNumber.toString().length > 7) {
      errorMessage = "Imo number must be a 7 digit number";
    }
  }
  return errorMessage;
};

Vessel.prototype.getDraftMeanErrorMessage = function () {
  var errorMessage;
  var draftMean = this.draftMean();

  if (_.isUndefined(draftMean) || draftMean === "") {
    errorMessage = "The Draft field is required";
  } else {
    draftMean = parseFloat(draftMean);
    if (!_.isFinite(draftMean)) {
      errorMessage = "The Draft field must be a number";
    }
    else if (draftMean < 0 || draftMean > 25) {
      errorMessage = "The Draft field must be between 0 and 25";
    }
  }
  return errorMessage;
};

Vessel.prototype.getDraftErrorMessage = function (draftValue, fieldName) {
  let errorMessage;

  if (draftValue !== undefined && draftValue !== "") {
    draftValue = parseFloat(draftValue);
    if (isNaN(draftValue)) {
      errorMessage = `The ${fieldName} field must be a number`;
    } else if (draftValue < 0 || draftValue > 25) {
      errorMessage = `The ${fieldName} field must be between 0 and 25`;
    }
  }
  return errorMessage;
};

Vessel.prototype.getBallastDraftHighErrorMessage = function () {
  return this.getDraftErrorMessage(this.ballastDraftHigh(), "Ballast Draft High");
};

Vessel.prototype.getBallastDraftLowErrorMessage = function () {
  return this.getDraftErrorMessage(this.ballastDraftLow(), "Ballast Draft Low");
};

Vessel.prototype.getLadenDraftHighErrorMessage = function () {
  return this.getDraftErrorMessage(this.ladenDraftHigh(), "Laden Draft High");
};

Vessel.prototype.getLadenDraftLowErrorMessage = function () {
  return this.getDraftErrorMessage(this.ladenDraftLow(), "Laden Draft Low");
};

Vessel.prototype.getMaxContinuousRatingErrorMessage = function () {
  let errorMessage;
  const maxContinuousRating = this.maxContinuousRating();

  if (!_.isUndefined(maxContinuousRating) && maxContinuousRating !== "") {
    const maxContinuousRatingValue = parseFloat(maxContinuousRating);
    if (isNaN(maxContinuousRatingValue)) {
      errorMessage = "The Max Continuous Rating field must be a number";
    } else if (maxContinuousRatingValue < 0) {
      errorMessage = "The Max Continuous Rating field must be greater than 0";
    }
  }
  else {
    errorMessage = "The Max Continuous Rating field is required";
  }

  return errorMessage;
};

Vessel.prototype.getSpeedMaxContinuousRatingErrorMessage = function () {
  let errorMessage;
  const maxContinuousRating = this.speedMaxContinuousRating();

  if (!_.isUndefined(maxContinuousRating) && maxContinuousRating !== "") {
    const maxContinuousRatingValue = parseFloat(maxContinuousRating);
    if (isNaN(maxContinuousRatingValue)) {
      errorMessage = "The Max Continuous Rating field must be a number";
    } else if (maxContinuousRatingValue < 0) {
      errorMessage = "The Max Continuous Rating field must be greater than 0";
    }
  }

  return errorMessage;
};

Vessel.prototype.getCompany = function (companyName) {
  return _.find(this.companies, function (company) {
    return company.name === companyName;
  });
};

Vessel.prototype.getVessel = function () {
  var existingVessel = this.vessel();
  var vessel = existingVessel ? _.clone(existingVessel) : {};
  vessel.id = this.id();
  vessel.name = this.name();
  vessel.companyName = this.companyName();
  vessel.imoNumber = this.imoNumber();
  vessel.created = this.created();
  vessel.draftMean = this.draftMean();
  vessel.ballastDraftHigh = this.ballastDraftHigh();
  vessel.ballastDraftLow = this.ballastDraftLow();
  vessel.ladenDraftHigh = this.ladenDraftHigh();
  vessel.ladenDraftLow = this.ladenDraftLow();
  vessel.maxContinuousRating = this.maxContinuousRating();
  vessel.speedMaxContinuousRating = this.speedMaxContinuousRating();
  vessel.numberOfShafts = this.numberOfShafts();
  vessel.isDataReceiver = this.isDataReceiver();
  vessel.activeStatus = this.activeStatus();
  vessel.features = this.enabledFeatures;

  return vessel;
};

Vessel.prototype.update = function (type) {
  var self = this;
  var updateType = typeof type === "string" ? type : "editVessel";
  var vessel = this.getVessel();
  var company = this.company();
  var companyId = company ? company.id : 0;
  var draftErrorMessage = self.getDraftMeanErrorMessage();
  var ballastDraftHighErrorMessage = self.getBallastDraftHighErrorMessage();
  var ballastDraftLowErrorMessage = self.getBallastDraftLowErrorMessage();
  var ladenDraftHighErrorMessage = self.getLadenDraftHighErrorMessage();
  var ladenDraftLowErrorMessage = self.getLadenDraftLowErrorMessage();
  var maxContinuousRatingErrorMessage = self.getMaxContinuousRatingErrorMessage();
  var speedMaxContinuousRatingErrorMessage = self.getSpeedMaxContinuousRatingErrorMessage();
  var imonNumberErrorMessage = self.getImoNumberErrorMessage();
  var nameErrorMessage = self.getNameErrorMessage();

  if (!draftErrorMessage && !imonNumberErrorMessage && !nameErrorMessage) {
    if (vessel.id) {
      vesselsAdministrationsClient.update(vessel).done(function () {
        events.vesselAdministrationUpdated.dispatch(updateType, vessel.name);
      });
    } else {
      vesselsAdministrationsClient.createVessel(vessel).done(function (data) {
        var doneCallback = function () {
          var menuItem = company ? "assignedVessels" : "unassignedVessels";
          events.vesselAdministrationUpdated.dispatch("createVessel", vessel.name);
          events.menuItemClick.dispatch(menuItem);
        };
        if (companyId) {
          vesselsAdministrationsClient.assign(data.id, companyId).done(doneCallback);
        } else {
          doneCallback();
        }
      });
    }
  } else {
    self.validateField(draftErrorMessage, self.draftMeanErrorMessage, self.isDraftValid);
    self.validateField(imonNumberErrorMessage, self.imoNumberErrorMessage, self.isImoNumberValid);
    self.validateField(nameErrorMessage, self.nameErrorMessage, self.isNameValid);
    self.validateField(ballastDraftHighErrorMessage, self.ballastDraftHighErrorMessage, self.isBallastDraftHighValid);
    self.validateField(ballastDraftLowErrorMessage, self.ballastDraftLowErrorMessage, self.isBallastDraftLowValid);
    self.validateField(ladenDraftHighErrorMessage, self.ladenDraftHighErrorMessage, self.isLadenDraftHighValid);
    self.validateField(ladenDraftLowErrorMessage, self.ladenDraftLowErrorMessage, self.isLadenDraftLowValid);
    self.validateField(maxContinuousRatingErrorMessage, self.maxContinuousRatingErrorMessage, self.isMaxContinuousRatingValid);
    self.validateField(speedMaxContinuousRatingErrorMessage, self.speedMaxContinuousRatingErrorMessage, self.isSpeedMaxContinuousRatingValid);
  }
};

Vessel.prototype.archive = function () {
  vesselsAdministrationsClient.archiveVessel(this.id()).done(() => {
    events.vesselAdministrationUpdated.dispatch("archiveVessel", this.name());
    events.menuItemClick.dispatch("assignedVessels");
    store.dispatch("Vessels/refreshExtendedVessels");
  });
};

Vessel.prototype.activate = function () {
  vesselsAdministrationsClient.activateVessel(this.id()).done(() => {
    events.vesselAdministrationUpdated.dispatch("activateVessel", this.name());
    events.menuItemClick.dispatch("assignedVessels");
    store.dispatch("Vessels/refreshExtendedVessels");
  });
};

Vessel.prototype.deactivate = function () {
  vesselsAdministrationsClient.deactivateVessel(this.id()).done(() => {
    events.vesselAdministrationUpdated.dispatch("deactivateVessel", this.name());
    events.menuItemClick.dispatch("assignedVessels");
    store.dispatch("Vessels/refreshExtendedVessels");
  });
};

Vessel.prototype.reactivate = function () {
  vesselsAdministrationsClient.activateVessel(this.id()).done(() => {
    events.vesselAdministrationUpdated.dispatch("activateVessel", this.name());
    events.menuItemClick.dispatch("archivedVessels");
    store.dispatch("Vessels/refreshExtendedVessels");
  });
};

Vessel.prototype.updateDataReceiver = function () {
  var self = this;
  var promise;
  var newIsDataReceiver = !this.isDataReceiver();
  var actionName = "";

  var vesselId = this.id();
  if (newIsDataReceiver) {
    promise = vesselsAdministrationsClient.setAsDataReceiver(vesselId);
    actionName = "setAsDataReceiver";
  } else {
    promise = vesselsAdministrationsClient.unsetAsDataReceiver(vesselId);
    actionName = "unsetAsDataReceiver";
  }
  promise.done(() => {
    self.isDataReceiver(newIsDataReceiver);
    events.vesselAdministrationUpdated.dispatch(actionName, this.name());
    events.findDataReceiver.dispatch();
  });

  return promise;
};

Vessel.prototype.deleteVessel = function () {
  var promise;
  var confirmResult = confirm("Are you sure you want to delete " + this.name() + "?");
  if (confirmResult) {
    promise = vesselsAdministrationsClient.deleteVessel(this.id());
    promise.done(() => {
      events.vesselDeleted.dispatch("deleteVessel", this.name());
      store.dispatch("Vessels/refreshExtendedVessels");
    });
  }
  return promise;
};

Vessel.prototype.dispose = function () {
  this.imoNumberBinding.dispose();
  this.draftMeanBinding.dispose();
  this.ballastDraftHighBinding.dispose();
  this.ballastDraftLowBinding.dispose();
  this.ladenDraftHighBinding.dispose();
  this.ladenDraftLowBinding.dispose();
  this.maxContinuousRatingBinding.dispose();
  this.speedMaxContinuousRatingBinding.dispose();
  this.nameBinding.dispose();
};

export default Vessel;
